import React from 'react';
import {
  ActivityIndicator,
  View
} from 'react-native';

// Local
import {Colors} from '../constants';

const Loader = () => {
  const styles = {
    container: {
      width: "100%",
      padding: 120,
    }
  };
  return (
    <View style={styles.container}>
      <ActivityIndicator size={'large'} color={Colors.accent}/>
    </View>
  );
};

export default Loader;
