import * as React from 'react';
import {AsyncStorage, Image, ScrollView, StyleSheet, Text, View} from 'react-native';
import { useMediaQuery } from 'react-responsive'

// Local
import {Categories} from '../data';
import {Colors, Measurements} from '../constants';
import Button from '../components/Button';
import {CartContext} from "../context";
import Footer from "../components/Footer";

function Header({}) {
	const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Measurements.tabletBreakPt}px)` });
	const styles = StyleSheet.create({
		container: {
			width: '100%',
			paddingVertical: 14,
			backgroundColor: Colors.primary,
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
		},
		link: {
			paddingHorizontal: 16,
		},
		linkText: {
			color: '#ffffff',
			fontWeight: isTabletOrMobile ? '500' : '600',
			fontSize: isTabletOrMobile ? 13 : 15,
		}
	});
	return (
		<View style={styles.container}>
			{Categories.map(category => (
				<View
					key={category.slug}
					style={styles.link}>
					<a
						href={`#${category.slug}`}
						style={{textDecoration: 'none'}}>
						<Text style={styles.linkText}>{category.name.toUpperCase()}</Text>
					</a>
				</View>
			))}
		</View>
	)
}

function Product({cart = [], description, id, image, name, price, right = false, updateCart}) {
	const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Measurements.tabletBreakPt}px)` });
	if (isTabletOrMobile) right = false;
	const styles = StyleSheet.create({
		container: {
			minHeight: 800,
			width: '100%',
			maxWidth: 1024,
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 'auto',
			flexWrap: 'wrap',
		},
		image: {
			height: isTabletOrMobile? 400 : 720,
			width: isTabletOrMobile? '100%' : 720,
		},
		info: {
			minHeight: 400,
			minWidth: 304,
			flex: 1,
			paddingHorizontal: 24,
		},
		name: {
			fontSize: 32,
			color: '#656565'
		},
		price: {
			fontSize: 18,
			fontWeight: '500',
			marginTop: 6,
		},
		description: {
			fontSize: 15,
			color: '#555555',
			marginTop: 16,
			marginBottom: 28,
			lineHeight: 24,
		},
	});
	return (
		<View style={styles.container}>
			{!right && <Image style={styles.image} resizeMode={'contain'} source={image}/>}
			<View style={styles.info}>
				<Text style={styles.name}>{name}</Text>
				<Text style={styles.price}>BBD ${Number(price).toFixed(2)}</Text>
				<Text style={styles.description}>{description}</Text>
				<Button onPress={() => {
					const newCart = [...cart];
					let hasItem = false;
					let newItem = {name, price, id, image, quantity: 1};
					for (let i = 0; i < cart.length; i++){
						const item = cart[i];
						if (item.id === id) {
							hasItem = true;
							newItem = {...item, quantity: item.quantity + 1};
							newCart[i] = newItem;
							break;
						}
					}
					
					if (!hasItem){
						newCart.push(newItem);
					}
					
					AsyncStorage.setItem('@cart', JSON.stringify(newCart)).then(()=> {
						updateCart({type: 'init', payload: {cart: newCart}});
						alert(`${name} has been added to your cart. Close to continue shopping`);
					});
				}}>Add to cart</Button>
			</View>
			{right && <Image style={styles.image} resizeMode={'contain'} source={image}/>}
		</View>
	)
}

export default function Home() {
	const cartContext = React.useContext(CartContext);
	const styles = StyleSheet.create({
		container: {
			position: 'absolute',
			top: Measurements.headerHeight,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: '#fff',
		},
	});
	return (
		<View style={styles.container}>
			<Header/>
			<ScrollView>
				{Categories.map((category, index) => (
					<div
						key={category.slug}
						id={category.slug}
						style={{
							minHeight: 800,
							width: '100%',
							paddingTop: 16,
							paddingBottom: 16,
							borderBottom: "1px solid #e9e9e9",
						}}>
						{category.products.map((product, index) => (
							<Product
								{...product}
								right={index % 2 == 0}
								cart={cartContext.cart}
								updateCart={cartContext.updateCart}/>
						))}
					</div>
				))}
				<Footer/>
			</ScrollView>
		</View>
	);
}
