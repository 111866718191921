import * as React from 'react';
import {CheckBox, Image, Linking, Picker, ScrollView, StyleSheet, Text, TextInput, View} from "react-native";
import {Formik} from 'formik';
import {useMediaQuery} from 'react-responsive'

// Local
import {Colors, Icons, Measurements, Strings} from '../constants';
import Button from '../components/Button';
import {Parishes} from '../data';
import {CartContext} from "../context";
import Footer from "../components/Footer";

const CheckedItem = (props) => {
	const {isChecked = false, label, onChange} = props;
	const styles = StyleSheet.create({
		container: {
			marginTop: 24,
			flexDirection: 'row',
			alignItems: 'center',
			marginRight: 32,
		},
		label: {
			fontSize: 17,
			color: Colors.accent,
			marginLeft: 8,
		},
	});
	return (
		<View style={styles.container}>
			<CheckBox value={isChecked} onChange={onChange}/>
			<Text style={styles.label}>
				{label}
			</Text>
		</View>
	)
};

const Input = ({onChangeText, editable = true, error, label, multiline = false, placeholder, value}) => {
	const styles = StyleSheet.create({
		container: {
			marginTop: 24,
		},
		label: {
			fontSize: 18,
			color: Colors.accent,
		},
		inputContainer: {
			marginTop: 12,
			borderRadius: 4,
			borderColor: error ? 'red' : '#dddddd',
			borderWidth: 1,
			height: multiline ? 128 : 44,
			justifyContent: multiline ? 'flex-start' : 'center',
			padding: 16,
		},
		errorText: {
			color: 'red',
			marginTop: 2
		}
	});
	const allProps = Object.assign({}, {onChangeText, multiline, placeholder, value});
	return (
		<View style={styles.container}>
			<Text style={styles.label}>
				{label}
			</Text>
			<View style={styles.inputContainer}>
				<TextInput {...allProps}/>
			</View>
			{
				error &&
				<Text style={styles.errorText}>
					{error}
				</Text>
			}
		</View>
	)
};

const ParishesPicker = ({delivery = false, selectedParish, selectedDeliveryParish}) => {
	if (delivery) selectedParish = selectedDeliveryParish;
	const styles = StyleSheet.create({
		container: {
			marginTop: 24,
		},
		label: {
			fontSize: 18,
			color: Colors.accent,
		},
		picker: {
			height: 44,
			marginVertical: 16,
		},
	});
	const pickerParishes = [];
	for (let i = 0; i < Parishes.length; i++) {
		const parish = Parishes[i];
		pickerParishes.push(
			<Picker.Item key={i} label={parish.label} value={parish.value}/>
		)
	}
	return (
		<View style={styles.container}>
			<Text style={styles.label}>
				Parish
			</Text>
			<Picker
				selectedValue={selectedParish.value}
				style={styles.picker}
				onValueChange={(itemValue, itemIndex) => {
					const selectedParish = Parishes[itemIndex];
					//if (delivery) this.setState({selectedDeliveryParish: selectedParish});
					//else this.setState({selectedParish})
				}}>
				{pickerParishes}
			</Picker>
		</View>
	);
};

const Checkout = ({navigation}) => {
	const cartContext = React.useContext(CartContext);
	const [termsAccepted, acceptTerms] = React.useState(false);
	const [state, setState] = React.useState({
		selectedParish: Parishes[0],
		selectedDeliveryParish: Parishes[0],
		isSameAddress: true,
		submitResponse: null,
	});
	const cartTotal = () => {
		let total = 0;
		cartContext.cart.forEach(item => {
			const itemTotal = item.price * item.quantity;
			total = total + itemTotal;
		});
		return total;
	};
	const isTabletOrMobile = useMediaQuery({query: `(max-width: ${Measurements.tabletBreakPt}px)`});
	const {isSameAddress, selectedParish, selectedDeliveryParish, submitResponse} = state;
	const contentWidth = 1024;
	const moreThan600 = contentWidth > 600;
	const styles = StyleSheet.create({
		container: {
			position: 'absolute',
			top: Measurements.headerHeight,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: '#fff',
		},
		content: {
			width: '100%',
			maxWidth: 1024,
			minHeight: 300,
			flexDirection: isTabletOrMobile ? 'column' : 'row',
			margin: 'auto',
		},
		form: {
			flex: 1,
			width: '100%',
			paddingBottom: 56,
			paddingHorizontal: isTabletOrMobile ? 16 : 0,
			marginHorizontal: 'auto',
		},
		title: {
			color: Colors.accent,
			textAlign: 'center'
		},
		subtext: {
			textAlign: 'center',
			marginTop: 12,
			color: Colors.accent,
		},
		checkGroup: {
			flexDirection: moreThan600 ? 'row' : 'column',
		},
		submitContainer: {
			alignSelf: 'center',
			alignItems: 'center',
		},
		icon: {
			height: 96,
			width: 96,
			alignSelf: 'center',
			marginTop: 16,
		},
		submitResponse: {
			color: submitResponse ? submitResponse?.type === 'failure' ? 'red' : '#2dae85' : 'transparent',
			marginBottom: 12,
			marginTop: submitResponse?.type === 'failure' ? -24 : 16,
			textAlign: 'center',
			fontSize: 18
		},
		summary: {
			width: isTabletOrMobile ? '100%' : 300,
			height: 184,
			padding: 16,
			borderWidth: 1,
			borderColor: "#e9e9e9",
			borderRadius: 2,
			marginLeft: isTabletOrMobile ? 0 : 32,
			marginTop: isTabletOrMobile ? 0 : 24,
		},
		summaryRow: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		summaryText: {},
		summaryValue: {
			fontWeight: '600'
		},
		disclaimerContainer: {
			width: '100%',
			marginVertical: 16,
			flexDirection: 'row',
		},
		disclaimer: {
			marginLeft: 8,
		},
		disclaimerLink: {
			color: Colors.accent
		}
	});
	const Summary = () => (
		<View style={styles.summary}>
			<View style={styles.summaryRow}>
				<Text style={styles.summaryText}>Subtotal:</Text>
				<Text style={styles.summaryValue}>BBD ${Number(cartTotal()).toFixed(2)}</Text>
			</View>
			<View style={{height: 16}}/>
			<Button
				disabled={!termsAccepted}
				onPress={() => navigation.navigate('OrderConfirmation')}>
				Pay now
			</Button>
			<View style={styles.disclaimerContainer}>
				<CheckBox value={termsAccepted} onValueChange={(value)=> acceptTerms(value)}/>
				<Text style={styles.disclaimer}>
					By placing your order, you agree to our
					<Text style={styles.disclaimerLink} onPress={() => Linking.openURL(Strings.refundUrl)}>
						{' '}Returns Policy
					</Text>
					,
					<Text style={styles.disclaimerLink} onPress={() => Linking.openURL(Strings.privacyUrl)}>
						{' '}Privacy Policy{' '}
					</Text>
					and
					<Text style={styles.disclaimerLink} onPress={() => Linking.openURL(Strings.termsUrl)}>
						{' '}Terms & Conditions
					</Text>
					.
				</Text>
			</View>
		</View>
	);
	return (
		<Formik
			initialValues={{
				cardNumber: '',
				cardDate: '',
				cardCode: '',
				name: '',
				email: '',
				number: '',
				address: '',
				deliveryAddress: ''
			}}
			onSubmit={values => {
			}}>
			{({handleChange, handleSubmit, values, errors}) => (
				<View style={styles.container}>
					{isTabletOrMobile && <Summary/>}
					<ScrollView contentContainerStyle={{paddingTop: isTabletOrMobile ? 0 : 80}}>
						<View style={styles.content}>
							<View style={styles.form}>
								<View>
									<Input
										onChangeText={handleChange('cardNumber')}
										value={values.cardNumber}
										error={errors.cardNumber}
										placeholder="Enter your card number"
										label='Card Number'
									/>
									<Input
										onChangeText={handleChange('cardDate')}
										value={values.cardDate}
										error={errors.cardDate}
										placeholder="Enter your card's expiration date"
										label='Expiration Date'
									/>
									<Input
										onChangeText={handleChange('cardCode')}
										value={values.cardCode}
										error={errors.cardCode}
										placeholder="Enter the security code at the back of your card"
										label='Security Code'
									/>
									<Input
										onChangeText={handleChange('name')}
										value={values.name}
										error={errors.name}
										placeholder="Enter your full name"
										label='Name'
									/>
									<Input
										onChangeText={handleChange('email')}
										value={values.email}
										error={errors.email}
										placeholder="Enter your email address"
										label='Email Address'
									/>
									<Input
										onChangeText={handleChange('number')}
										value={values.number}
										error={errors.number}
										placeholder="Enter your telephone number"
										label='Telephone Number'
									/>
									<Input
										onChangeText={handleChange('address')}
										value={values.address}
										error={errors.address}
										placeholder="Enter billing address"
										label='Billing Address'
										multiline={true}
									/>
									<ParishesPicker
										selectedParish={selectedParish}
										selectedDeliveryParish={selectedDeliveryParish}/>
									<CheckedItem
										isChecked={isSameAddress}
										label='Same as Billing Address'
										onChange={() => {
										}}/>
									<Input
										onChangeText={handleChange('deliveryAddress')}
										value={isSameAddress ? values.address : values.deliveryAddress}
										error={isSameAddress ? errors.address : errors.deliveryAddress}
										editable={!isSameAddress}
										placeholder="Enter delivery address"
										label='Delivery Address'
										multiline={true}
									/>
									<ParishesPicker
										selectedParish={selectedParish}
										selectedDeliveryParish={selectedDeliveryParish}
										delivery={!isSameAddress}/>
									{/*
                    (!submitResponse || submitResponse?.type === 'failure') &&
                    (
                      <Button onPress={handleSubmit}>Send</Button>
                    )
                  */}
									{
										submitResponse &&
										<View style={styles.submitContainer}>
											{
												submitResponse?.type === 'success' &&
												<Image style={styles.icon} source={{uri: Icons.checkmarkColored}}/>
											}
											<Text style={styles.submitResponse}>
												{submitResponse.response}
											</Text>
										</View>
									}
								</View>
							</View>
							{!isTabletOrMobile && <Summary/>}
						</View>
						<Footer/>
					</ScrollView>
				</View>
			)}
		</Formik>
	)
};

export default Checkout;
