import * as React from 'react';
import {AsyncStorage, StyleSheet, View} from 'react-native';

// Local
import Navigator from './navigation/Navigator';
import {CartContext} from "./context";
import {CartReducer} from "./reducers";
import Loader from './components/Loader';

export default function App() {
	const [isReady, setIsReady] = React.useState(false);
	const [state, dispatch] = React.useReducer(CartReducer, {cart: []});
	const styles = StyleSheet.create({
		container: {
			flex: 1,
			backgroundColor: '#fff',
		},
	});
	
	React.useEffect(() => {
		AsyncStorage.getItem('@cart').then((value) => {
			if (value) {
				dispatch({type: 'init', payload: {cart: JSON.parse(value)}});
			}
			setIsReady(true);
		});
	}, []);
	
	if (!isReady) {
		return (
			<Loader/>
		);
	} else {
		return (
			<CartContext.Provider value={{cart: state.cart, updateCart: dispatch}}>
				<View style={styles.container}>
					<Navigator/>
				</View>
			</CartContext.Provider>
		);
	}
}
