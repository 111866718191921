import * as React from 'react';
import {Image, Linking, StyleSheet, Text, View} from "react-native";
import {Divider} from "react-native-paper";
import { useMediaQuery } from 'react-responsive'

//Local
import {Measurements, Strings} from "../constants";

const Footer = () => {
	const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Measurements.tabletBreakPt}px)` });
	const styles = StyleSheet.create({
		container: {
			backgroundColor: '#333333',
		},
		content: {
			margin: 'auto',
			maxWidth: 1024,
			width: '100%',
			paddingVertical: 80,
			paddingHorizontal: isTabletOrMobile ? 32 : 0,
		},
		row: {
			width: '100%',
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'space-between'
		},
		column: {
			width: 320
		},
		title: {
			fontSize: 24,
			fontWeight: '600',
			color: '#ffffff',
			marginVertical: 16,
		},
		text: {
			fontSize: 16,
			color: '#ffffff',
			lineHeight: 28,
		},
		divider: {
			marginVertical: 16
		},
		paymentOptions: {
			backgroundColor: '#212121',
			width: '100%',
			flexDirection: 'row',
			justifyContent: 'center',
			padding: 16,
		},
		paymentOptionImg: {
			width: 64,
			height: 32,
		}
	});
	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View style={styles.row}>
					<View style={styles.column}>
						<Text style={styles.title}>Support</Text>
						<Text style={styles.text}>{Strings.contact}</Text>
						<Text style={styles.text} onPress={()=> Linking.openURL(Strings.refundUrl)}>
							Return Policy
						</Text>
						<Text style={styles.text} onPress={()=> Linking.openURL(Strings.privacyUrl)}>
							Privacy Policy
						</Text>
						<Text style={styles.text} onPress={()=> Linking.openURL(Strings.termsUrl)}>
							Terms & Conditions
						</Text>
					</View>
					<View style={styles.column}>
						<Text style={styles.title}>Offices</Text>
						<Text style={styles.text}>{Strings.location}</Text>
					</View>
					<View style={styles.column}>
						<Text style={styles.title}>Delivery</Text>
						<Text style={styles.text}>{Strings.delivery}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.column}>
					</View>
				</View>
			</View>
			<View style={styles.paymentOptions}>
				<Image
					source={require('../assets/logo-visa.jpg')}
					style={styles.paymentOptionImg}
					resizeMode={'contain'}/>
				<Image
					source={require('../assets/logo-mastercard.png')}
					style={styles.paymentOptionImg}
					resizeMode={'contain'}/>
			</View>
		</View>
	);
};

export default Footer;
