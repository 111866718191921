import * as React from 'react';

import {
	createNavigator,
	SwitchRouter,
} from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";

// Local
import WebView from "./WebView";
import Home from '../pages/Home';
import Cart from '../pages/Cart';
import Checkout from '../pages/Checkout';
import OrderConfirmation from '../pages/OrderConfirmation';

const WebNavigator = createNavigator(
	WebView,
	SwitchRouter({
		Home: {
			screen: Home,
			path: ""
		},
		Cart: {
			screen: Cart,
			path: "cart"
		},
		Checkout: {
			screen: Checkout,
			path: "checkout"
		},
		OrderConfirmation: {
			screen: OrderConfirmation,
			path: "order-confirmation"
		},
	}),
	{}
);

export default createBrowserApp(WebNavigator);
