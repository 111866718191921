

const ElectricCars = [
	{
		price: 33000,
		id: 'rhd-electric-car',
		name: 'RHD ELECTRIC CAR',
		image: require('../assets/rhd-electric-car-side.png'),
		category: 'ELECTRIC CARS',
		description: 'Range: 150 - 200 KM\n' +
			'Charging Time From O is 8-10H\n' +
			'Max Speed: 100km/h\n' +
			'​Persons: 5 Passengers\n' +
			'​Motor Type: AC'
	},
];

const ElectricBikes = [
	{
		price: 5390,
		id: 'z3-intelligent-e-vehicle',
		name: 'Z3 Intelligent E-Vehicle',
		image: require('../assets/z3-intelligent-e-vehicle.png'),
		category: 'ELECTRIC BIKES',
		description: 'One button start\n' +
			'One button detection\n' +
			'One button positioning\n' +
			'One button seat opening\n' +
			'One button rescue'
	},
	{
		price: 3500,
		id: 'e-wild-50c',
		name: 'E-WILD 50C, 250W, PEDELEC ELECTRIC BIKE',
		image: require('../assets/e-wild-50c.png'),
		category: 'ELECTRIC BIKES',
		description: 'Bafang Max Mid Motor\n' +
			'Power Pack 36V 468WH Battery\n' +
			'Charger Times 2-5H\n' +
			'Torque Sensor'
	},
];

const Panels = [
	{
		price: 400,
		id: 'solar-panels',
		name: 'Solar Panels',
		image: require('../assets/solar-panels.png'),
		category: 'PANELS',
		description: ''
	},
];

const Categories = [
	{name: 'ELECTRIC BIKES', slug: 'electric-bikes', products: ElectricBikes},
	{name: 'ELECTRIC CARS', slug: 'electric-cars', products: ElectricCars},
	{name: 'PANELS', slug: 'panels', products: Panels}
];

const Products = ElectricBikes.concat(ElectricCars).concat(Panels);

const Parishes = [
	{label: 'Christ Church', value: 'chch', deliveryFee: 15.00},
	{label: 'St. Andrew', value: 'st-andrew', deliveryFee: 20.00},
	{label: 'St. George', value: 'st-george', deliveryFee: 20.00},
	{label: 'St. James', value: 'st-james', deliveryFee: 15.00},
	{label: 'St. John', value: 'st-john', deliveryFee: 25.00},
	{label: 'St. Joseph', value: 'st-joseph', deliveryFee: 25.00},
	{label: 'St. Lucy', value: 'st-lucy', deliveryFee: 20.00},
	{label: 'St. Michael', value: 'st-michael', deliveryFee: 15.00},
	{label: 'St. Peter', value: 'st-peter', deliveryFee: 20.00},
	{label: 'St. Philip', value: 'st-philip', deliveryFee: 25.00},
	{label: 'St. Thomas', value: 'st-thomas', deliveryFee: 20.00},
];

export {
	Categories,
	Parishes,
	Products
}
