import * as React from 'react';
import {AsyncStorage, FlatList, Image, ScrollView, StyleSheet, Text, View} from 'react-native';
import {IconButton} from "react-native-paper";
import { useMediaQuery } from 'react-responsive'

// Local
import Button from "../components/Button";
import {CartContext} from "../context";
import Footer from "../components/Footer";
import {Measurements} from "../constants";

function Product({cart, id, image, index, name, price, quantity, updateCart}) {
	const item = {name, image, price, quantity, id};
	const updateQuantity = (increase = true)=> {
		const newItem = {...item};
		if (increase){
			newItem.quantity = item.quantity + 1;
		} else {
			if (item.quantity >= 2){
				newItem.quantity = item.quantity - 1;
			}
		}
		const newCart = [...cart];
		newCart[index] = newItem;
		AsyncStorage.setItem('@cart', JSON.stringify(newCart)).then(()=> {
			updateCart({type: 'init', payload: {cart: newCart}});
		});
	};
	const styles = StyleSheet.create({
		container: {
			width: '100%',
			padding: 16,
			flexDirection: 'row',
			borderBottomWidth: 1,
			borderBottomColor: "#e9e9e9",
		},
		image: {
			width: 120,
			height: 120,
		},
		info: {
			flex: 1,
			marginLeft: 24,
		},
		name: {
			fontSize: 22,
			color: '#656565'
		},
		price: {
			fontSize: 18,
			fontWeight: '600',
			marginTop: 8,
		},
		quantityContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			marginTop: 16,
			marginBottom: 24,
			borderRadius: 2,
			borderColor: '#e1e1e1',
			borderWidth: 1,
			width: 100
		},
		quantity: {
			fontSize: 15,
			fontWeight: '500',
			marginHorizontal: 6,
		},
		delete: {
			width: 80,
			height: 32,
			backgroundColor: 'red'
		}
	});
	return (
		<View style={styles.container}>
			<Image resizeMode={'contain'} source={image} style={styles.image}/>
			<View style={styles.info}>
				<Text style={styles.name}>{name}</Text>
				<Text style={styles.price}>BBD ${Number(price).toFixed(2)}</Text>
				<View style={styles.quantityContainer}>
					<IconButton icon={'minus'} size={18} color={'#757575'} onPress={()=> updateQuantity(false)}/>
					<Text style={styles.quantity}>{quantity}</Text>
					<IconButton icon={'plus'} size={18} color={'#757575'} onPress={()=> updateQuantity()}/>
				</View>
				<Button
					style={styles.delete}
					onPress={()=> {
						const newCart = [...cart];
						newCart.splice(index, 1);
						AsyncStorage.setItem('@cart', JSON.stringify(newCart)).then(()=> {
							updateCart({type: 'init', payload: {cart: newCart}});
							alert(`${name} has been deleted from your cart. Close to continue shopping`);
						});
					}}>
					Delete
				</Button>
			</View>
		</View>
	)
}

export default function Cart({navigation}) {
	const cartContext = React.useContext(CartContext);
	const cartHasItems = cartContext.cart.length > 0;
	const cartTotal = ()=> {
		if (!cartHasItems) return 0;
		let total = 0;
		cartContext.cart.forEach(item => {
			const itemTotal = item.price * item.quantity;
			total = total + itemTotal;
		});
		return total;
	};
	const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Measurements.tabletBreakPt}px)` });
	const styles = StyleSheet.create({
		container: {
			position: 'absolute',
			top: Measurements.headerHeight,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: '#fff',
		},
		content: {
			width: '100%',
			maxWidth: 1024,
			minHeight: 560,
			flexDirection: isTabletOrMobile ? 'column' : 'row',
			margin: 'auto',
		},
		list: {
			flex: 1,
			width: '100%',
		},
		listEmptyText: {
			fontSize: 16,
			textAlign: 'center',
			marginTop: isTabletOrMobile ? 80 : 0
		},
		summary: {
			minWidth: 300,
			height: 112,
			padding: 16,
			borderWidth: 1,
			borderColor: "#e9e9e9",
			borderRadius: 2,
			marginLeft: isTabletOrMobile ? 0 : 16
		},
		summaryRow: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		summaryText: {},
		summaryValue: {
			fontWeight: '600'
		}
	});
	const Summary = ()=> (
		<View style={styles.summary}>
			<View style={styles.summaryRow}>
				<Text style={styles.summaryText}>Subtotal:</Text>
				<Text style={styles.summaryValue}>BBD ${Number(cartTotal()).toFixed(2)}</Text>
			</View>
			<View style={{height: 16}}/>
			<Button onPress={()=> navigation.navigate('Checkout')}>
				Checkout now
			</Button>
		</View>
	);
	return (
		<View style={styles.container}>
			<ScrollView contentContainerStyle={{paddingTop: isTabletOrMobile ? 0 : 80}}>
				<View style={styles.content}>
					{isTabletOrMobile && cartHasItems && <Summary/>}
					<FlatList
						ListEmptyComponent={
							<Text style={styles.listEmptyText}>Your cart is empty. Start shopping today!</Text>
						}
						style={styles.list}
						data={cartContext.cart}
						keyExtractor={(item, index) => index.toString()}
						renderItem={({item, index}: any) => (
							<Product {...item} index={index} updateCart={cartContext.updateCart} cart={cartContext.cart}/>
						)}/>
					{!isTabletOrMobile && cartHasItems && <Summary/>}
				</View>
				<Footer/>
			</ScrollView>
		</View>
	);
}
