import * as React from "react";

interface CartProps {
	cart: any,
	updateCart: any
}

const CartContext = React.createContext<CartProps>({
	cart: [],
	updateCart: ()=> {}
});

export {CartContext};
